<template>
  <footer class="container-fluid mt-4">
    <hr class="border-primary" />
    <div class="float-right m-2 small text-secondary">
      {{ version }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'hFooter',
  data() {
    return {
      version: 'v1.1.10',
    }
  },
}
</script>

<style scoped></style>
